import React from 'react';
import { styled } from '@linaria/react';
import { Loader } from 'lucide-react';

import { normalize } from '@/utils';

interface Props extends React.HTMLAttributes<HTMLSpanElement> {
  size?: number;
  speed?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10;
  color?: string;
}

const Spinner = ({
  size = 24,
  color,
  speed = 5,
  style = {},
  ...delegated
}: Props) => {
  const duration = normalize(speed, 1, 10, 2400, 200);
  return (
    <Wrapper
      style={{
        width: size,
        height: size,
        '--duration': duration + 'ms',
        '--size': size + 'px',
        color,
        ...style,
      }}
      {...delegated}
    >
      <Loader size={size} />
    </Wrapper>
  );
};

const Wrapper = styled.span`
  display: block;
  color: inherit;
  opacity: 0.75;
  transform-origin: center center;
  line-height: 0px;

  @media (prefers-reduced-motion: no-preference) {
    animation: spin var(--duration) linear infinite;
  }

  svg {
    display: block !important;
    height: var(--size);
  }

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;

export default Spinner;
